import { Component } from '@angular/core';
import { menu } from '../../configs/menu';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrl: './menu.component.scss',
})
export class MenuComponent {
    readonly menu = menu;
}
