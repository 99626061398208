import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

import { IconsModule, SharedModule } from '@app/shared';
import { I18nModule } from '@app/i18n';
import { DisabledLinkDirective } from '@app/shared/directives';
import { ShellPanelComponent } from './components/shell/shell.component';
import { MenuComponent } from './components/menu/menu.component';

@NgModule({
    imports: [CommonModule, IconsModule, SharedModule, I18nModule, RouterModule, DisabledLinkDirective, MatToolbarModule, MatIconModule],
    declarations: [ShellPanelComponent, MenuComponent],
})
export class ShellPanelModule {}
