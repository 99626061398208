import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpClient, HttpBackend, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { I18nModule, TranslateLoaderStatic } from './i18n';
import { environment } from '@env/environment';
import { ShellPanelModule, ShellAuthModule } from './shells';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CoreModule } from '@corelabs/angular-core';
import { AppComponent } from './app.component';
import { HttpCoreService } from './core/services/http.service';
import { MessagesModule } from './modules/messages';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
// Stores
import { AdminUserStoreModule } from './store/admin-user';
import { LoadersStoreModule } from './store/loader';
import { AccountModule } from '@corelabs/angular-account';
import { UploaderModule } from './modules/uploader';
import { MediaStoreModule } from './store/media';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        I18nModule,
        AdminUserStoreModule,
        LoadersStoreModule,
        UploaderModule,
        MediaStoreModule,
        MessagesModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: () => {
                    return new TranslateLoaderStatic();
                },
                deps: [HttpBackend],
            },
        }),
        StoreModule.forRoot({}, {}),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument({
            // Devtools mmust be imported after StoreModule
            serialize: true,
            maxAge: 25,
            logOnly: environment.production,
            connectInZone: true,
        }),
        ShellAuthModule,
        ShellPanelModule,
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        CoreModule.forRoot({ serverUrl: environment.serverUrl }),
        AccountModule.forRoot(),
    ],
    providers: [
        {
            provide: HttpClient,
            useClass: HttpCoreService,
        },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
