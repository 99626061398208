import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AdminUserFacade } from '@app/store/admin-user';
import { AccountFacade } from '@corelabs/angular-account';
import { Subscription, filter, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CleanupService {
    private accountFacade = inject(AccountFacade);
    private adminUserFacade = inject(AdminUserFacade);
    private router = inject(Router);

    private subscriptions$ = new Subscription();

    constructor() {
        this.subscriptions$.add(
            this.accountFacade.loggedOut$
                .pipe(
                    filter((loggedOut) => loggedOut === true),
                    tap(() => {
                        this.clear();
                        this.router.navigate(['/auth/sign-in']);
                    }),
                )
                .subscribe(),
        );
    }

    private clear() {
        this.adminUserFacade.clear();
        this.accountFacade.clear();
    }
}
