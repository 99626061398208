import { Directive, ElementRef, Input, OnInit, Renderer2, inject } from '@angular/core';

@Directive({
    selector: '[appDisabledLink]',
    standalone: true,
})
export class DisabledLinkDirective implements OnInit {
    private elementRef: ElementRef<HTMLLinkElement> = inject(ElementRef);
    private renderer = inject(Renderer2);

    @Input({ required: true, alias: 'appDisabledLink' }) isDisabled?: boolean;

    ngOnInit() {
        const linkEl = this.elementRef.nativeElement;

        if (this.isDisabled) {
            this.renderer.addClass(linkEl, 'disabled-link');
            this.renderer.setAttribute(linkEl, 'tabIndex', '-1');
        } else {
            this.renderer.removeClass(linkEl, 'disabled-link');
            this.renderer.removeAttribute(linkEl, 'tabIndex');
        }
    }
}
