export const menu = [
    {
        name: 'header.nav.users',
        route: '/users',
    },
    {
        name: 'header.nav.spotify',
        route: '/spotify',
    },
];
