import { Injectable, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { BehaviorSubject, Subscription, combineLatest, debounceTime, of, startWith, tap } from 'rxjs';

@Injectable()
export class PaginationService implements OnDestroy {
    private route = inject(ActivatedRoute);
    private router = inject(Router);

    private readonly pageSizeOptions: number[] = [5, 10, 25, 100];
    private readonly subscriptions$ = new Subscription();
    private readonly pageSize$ = new BehaviorSubject(10);
    private readonly pageIndex$ = new BehaviorSubject(0);

    readonly paginationData$ = combineLatest({
        pageSizeOptions: of(this.pageSizeOptions),
        pageSize: this.pageSize$,
        pageIndex: this.pageIndex$,
    }).pipe(debounceTime(100));

    constructor() {
        this.subscriptions$.add(
            this.route.params
                .pipe(
                    startWith(this.route.snapshot.params),
                    tap((params) => {
                        this.pageSize$.next(params['pageSize'] ? +params['pageSize'] : this.pageSize$.value);
                        this.pageIndex$.next(params['pageIndex'] ? +params['pageIndex'] : this.pageIndex$.value);
                    }),
                )
                .subscribe(),
        );
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    onPageChange(event: PageEvent) {
        this.pageSize$.next(event.pageSize);
        this.pageIndex$.next(event.pageIndex);

        this.router.navigateByUrl(
            this.router
                .createUrlTree(
                    [
                        '.',
                        {
                            pageSize: this.pageSize$.value,
                            pageIndex: this.pageIndex$.value,
                        },
                    ],
                    { relativeTo: this.route },
                )
                .toString(),
        );
    }
}
