<div class="flex flex-col h-full overflow-hidden">
    <mat-toolbar color="primary" class="shrink-0">
        <div class="text-white flex items-center gap-6 w-full">
            <a routerLink="/">
                <img src="assets/svgs/logo-adm-white.svg" alt="" />
            </a>

            <app-menu />

            <a routerLink="/logout" class="ml-auto leading-none" [title]="'logout' | translate"> <mat-icon>logout</mat-icon> </a>
        </div>
    </mat-toolbar>

    <div class="grow overflow-auto">
        <router-outlet />
    </div>
</div>
