import { ApplicationRef, ComponentRef, Injectable, RendererFactory2, createComponent, inject } from '@angular/core';
import { Subscription, debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { LoadersFacade } from '@app/store/loader';
import { FullscreenLoaderComponent } from '@app/shared/components';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    private applicationRef = inject(ApplicationRef);
    private rendererFactory2 = inject(RendererFactory2);
    private renderer2 = this.rendererFactory2.createRenderer(null, null);
    private loadersFacade = inject(LoadersFacade);
    private componentRef!: ComponentRef<FullscreenLoaderComponent>;

    private subscriptions$ = new Subscription();

    constructor() {
        this.subscriptions$.add(
            this.loadersFacade.loading$
                .pipe(
                    debounceTime(50),
                    distinctUntilChanged(),
                    tap((loading) => {
                        if (loading) {
                            this.show();
                        } else {
                            this.hide();
                        }
                    }),
                )
                .subscribe(),
        );
    }

    private show(): void {
        this.componentRef = createComponent(FullscreenLoaderComponent, {
            environmentInjector: this.applicationRef.injector,
        });
        this.componentRef.location.nativeElement.style.zIndex = 9999;
        this.applicationRef.attachView(this.componentRef.hostView);
        this.renderer2.appendChild(document.querySelector('body'), this.componentRef.location.nativeElement);
    }

    private hide(): void {
        if (this.componentRef) {
            this.applicationRef.detachView(this.componentRef.hostView);
            this.componentRef.destroy();
        }
    }
}
