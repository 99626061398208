import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { AdminUserFacade } from '../facades/admin-user.facade';

export const adminUserCleanup: CanDeactivateFn<unknown> = () => {
    const adminUserFacade = inject(AdminUserFacade);

    adminUserFacade.clear();
    return true;
};
