import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule, SharedModule } from '@app/shared';
import { I18nModule } from '@app/i18n';
import { RouterModule } from '@angular/router';

import { ShellAuthComponent } from './components/shell/shell.component';

@NgModule({
    imports: [CommonModule, IconsModule, SharedModule, I18nModule, RouterModule],
    declarations: [ShellAuthComponent],
})
export class ShellAuthModule {}
