import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { filter, map, take, tap } from 'rxjs';
import { AdminUserFacade } from '../facades/admin-user.facade';

export function userLoaded(): CanActivateFn {
    return () => {
        const adminUserFacade = inject(AdminUserFacade);

        return adminUserFacade.loaded$.pipe(
            tap((loaded) => {
                if (loaded === null) {
                    adminUserFacade.getAdminUser();
                }
            }),
            filter((loaded) => loaded !== null),
            take(1),
            map(() => true),
        );
    };
}
