import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { usersActions } from '../actions/users.actions';
import { selectUsers, selectLoaded, selectTotalItems } from '../selectors/users.selector';
import { UsersValue } from '../models';

@Injectable({
    providedIn: 'root',
})
export class UsersFacade {
    private readonly store = inject(Store);

    users$ = this.store.select(selectUsers);
    loaded$ = this.store.select(selectLoaded);
    totalItems$ = this.store.select(selectTotalItems);

    getUsers(value: UsersValue): void {
        this.store.dispatch(usersActions.getUsers({ value }));
    }

    clear(): void {
        this.store.dispatch(usersActions.clear());
    }
}
