import { UserType, UserVerificationStatus } from '../types';

export interface UserDto {
    id: string;
    email: string;
    roles: string[];
    userType: UserType;
    language: 'pl' | 'en';
    verificationStatus: UserVerificationStatus;
    blocked: boolean;
    firstName: string;
    lastName: string;
    pseudonym: string;
    phoneNumber: string;
}
