export const environment = {
    production: false,
    sw: false,
    version: '1.0.0',
    serverUrl: 'https://api.adm.sandbox.cloud.codelabs.work/admin/',
    uploadUrl: 'api/v1/files/upload',
    assetsUrl: '',
    defaultLanguage: 'pl-PL',
    supportedLanguages: ['pl-PL', 'en-EN'] as const,
    icons: 'assets/svgs/icons.svg',
    cacheDurationInMinutes: 10,
    build: {
        time: true,
        commit: true,
    },
};
