import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { UploaderFacade } from '../facades/uploader.facade';

export function uploaderCleanup<T>(): CanDeactivateFn<T> {
    return () => {
        const uploaderFacade = inject(UploaderFacade);

        uploaderFacade.clear();
        return true;
    };
}
