import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { WindowService } from '@app/shared/services';
import { Subscription, tap } from 'rxjs';

@Component({
    selector: 'app-responsive-routing',
    template: '',
})
export class ResponsiveRoutingComponent implements OnInit, OnDestroy {
    protected readonly windowService = inject(WindowService);
    private readonly router = inject(Router);

    private readonly subscriptions$ = new Subscription();

    ngOnInit(): void {
        this.subscriptions$.add(
            this.windowService.lg$
                .pipe(
                    tap(() => {
                        this.router.navigateByUrl(this.router.url, { onSameUrlNavigation: 'reload' });
                    }),
                )
                .subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }
}
