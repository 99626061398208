import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { ADMIN_USER_FEATURE_KEY } from './keys';
import { adminUserReducer } from './reducers/admin-user.reducer';
import { AdminUserEffects } from './effects/admin-user.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(ADMIN_USER_FEATURE_KEY, adminUserReducer), EffectsModule.forFeature([AdminUserEffects])],
})
export class AdminUserStoreModule {}
