import { createReducer, on } from '@ngrx/store';
import { mediaActions } from '../actions/media.actions';
import { FileInfo } from '../models/file-info';

export interface MediaState {
    loaded: Map<string, boolean | null>;
    images: Map<string, FileInfo>;
}

export const initialState: MediaState = {
    loaded: new Map(),
    images: new Map(),
};

export const mediaReducer = createReducer(
    initialState,
    on(mediaActions.getImage, (state, payload): MediaState => {
        const loaded = new Map(state.loaded);

        loaded.set(payload.id, null);

        return {
            ...state,
            loaded,
        };
    }),

    on(mediaActions.getImageSuccess, (state, payload): MediaState => {
        const images = new Map(state.images);
        const loaded = new Map(state.loaded);

        images.set(payload.id, payload.fileInfo);
        loaded.set(payload.id, true);

        return {
            ...state,
            images,
            loaded,
        };
    }),

    on(mediaActions.getImageError, (state, payload): MediaState => {
        const loaded = new Map(state.loaded);

        loaded.set(payload.id, false);

        return {
            ...state,
            loaded,
        };
    }),

    on(
        mediaActions.clear,
        (): MediaState => ({
            ...initialState,
        }),
    ),
);
