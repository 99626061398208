import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map } from 'rxjs';
import { ApiListingResponse } from '@app/shared/models';
import { User, UserDto, UsersValue, UsersValueDto } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);

    getUsers(value: UsersValue) {
        const { params } = new UsersValueDto(value);

        return this.httpClient
            .get<ApiListingResponse<UserDto[]>>('api/v1/users', { params })
            .pipe(map(({ pagination, data }) => ({ totalItems: pagination.totalItems, users: data.map((userDto) => new User(userDto)) })));
    }
}
