import { ApplicationRef, ComponentRef, Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2, createComponent, inject } from '@angular/core';
import { LoaderComponent } from '@app/shared/components/loader';
import { MediaFacade } from '@app/store/media';
import { Subscription, combineLatest, distinctUntilChanged, filter, take, tap } from 'rxjs';

@Directive({
    selector: '[appLoadImage]',
})
export class LoadImageDirective implements OnInit, OnDestroy {
    private mediaFacade = inject(MediaFacade);
    private applicationRef = inject(ApplicationRef);
    private renderer = inject(Renderer2);
    private elementRef: ElementRef<HTMLDivElement> = inject(ElementRef);
    private spinnerRef?: ComponentRef<LoaderComponent>;

    @Input({ required: true, alias: 'appLoadImage' }) imageId: string | undefined;
    @Input() fallbackImage = '';
    @Input() imagePosition: 'center' | 'top' | 'bottom' = 'center';
    @Input() imageSize: 'contain' | 'cover' = 'contain';

    private subscriptions$ = new Subscription();

    ngOnInit() {
        this.attachLoader();

        if (!this.imageId) {
            this.renderImage();

            return;
        }

        this.mediaFacade.getImage(this.imageId);
        this.subscriptions$.add(
            combineLatest([this.mediaFacade.imageUrl$(this.imageId), this.mediaFacade.imageLoaded$(this.imageId)])
                .pipe(
                    distinctUntilChanged(),
                    filter(([, loaded]) => loaded !== null),
                    tap(([url]) => {
                        this.renderImage(url ?? undefined);
                    }),
                    take(1),
                )
                .subscribe(),
        );
    }

    ngOnDestroy() {
        this.detachLoader();
        this.subscriptions$.unsubscribe();
    }

    private attachLoader() {
        this.spinnerRef = createComponent(LoaderComponent, {
            environmentInjector: this.applicationRef.injector,
        });
        this.renderer.appendChild(this.elementRef.nativeElement, this.spinnerRef.location.nativeElement);
        this.applicationRef.attachView(this.spinnerRef.hostView);
    }

    private detachLoader() {
        if (this.spinnerRef) {
            this.applicationRef.detachView(this.spinnerRef.hostView);
            this.spinnerRef.destroy();
            delete this.spinnerRef;
        }
    }

    private renderImage(url?: string) {
        const img: HTMLImageElement = this.renderer.createElement('img');
        this.renderer.setAttribute(img, 'src', url || this.fallbackImage || 'assets/images/fallback-image.png');
        this.renderer.setAttribute(img, 'alt', '');
        this.renderer.setAttribute(img, 'style', `object-position: ${this.imagePosition}; aspect-ratio: 1; object-fit: ${this.imageSize};`);
        this.renderer.appendChild(this.elementRef.nativeElement, img);

        img.onload = () => {
            this.detachLoader();
        };
    }
}
