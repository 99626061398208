import { FileType } from '../types/file.type';
import { StatusType } from '../types/status.type';

export interface Item {
    uuid: string;
    id: string | null;
    name: string;
    url: string | null;
    progress: number;
    status: StatusType;
    file: File;
    fileType: FileType;
}
