import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { FileInfo } from '../models/file-info';

export const mediaActions = createActionGroup({
    source: 'Media',
    events: {
        'Get Image': props<{ id: string }>(),
        'Get Image Success': props<{ id: string; fileInfo: FileInfo }>(),
        'Get Image Error': props<{ id: string }>(),

        Clear: emptyProps(),
    },
});
