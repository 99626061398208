import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, catchError, finalize, map, mergeMap } from 'rxjs';
import { LoadersFacade } from '@app/store/loader';
import { usersActions } from '../actions/users.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class UsersEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);
    private readonly loadersFacade = inject(LoadersFacade);

    getUsers$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(usersActions.getUsers),
            mergeMap(({ value }) => {
                this.loadersFacade.add('get-users');

                return this.httpService.getUsers(value).pipe(
                    map((res) => usersActions.getUsersSuccess(res)),
                    catchError(() => of(usersActions.getUsersError())),
                    finalize(() => this.loadersFacade.delete('get-users')),
                );
            }),
        );
    });
}
