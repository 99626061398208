import { Lang } from '@app/i18n';
import { UserDto } from './user.dto';
import { UserType, UserVerificationStatus } from '../types';

export class User {
    id: string;
    email: string;
    roles: string[];
    type: UserType;
    language: Lang;
    firstName: string;
    lastName: string;
    pseudonym: string;
    phoneNumber: string;
    verificationStatus: UserVerificationStatus;
    blocked: boolean;

    constructor(userDto: UserDto) {
        this.id = userDto.id;
        this.email = userDto.email;
        this.roles = userDto.roles;
        this.firstName = userDto.firstName;
        this.lastName = userDto.lastName;
        this.pseudonym = userDto.pseudonym;
        this.phoneNumber = userDto.phoneNumber;
        this.type = userDto.userType;
        this.email = userDto.email;
        this.verificationStatus = userDto.verificationStatus;
        this.blocked = Boolean(userDto.blocked);

        switch (userDto.language) {
            case 'pl':
                this.language = 'pl-PL';
                break;
            case 'en':
                this.language = 'en-EN';
                break;
        }
    }
}
