<div class="flex flex-nowrap items-center">
    <div class="truncate flex-1 text-xs | lg:text-base">
        <span *ngIf="pending$ | async as pending; else preparingRef">
            {{ 'uploader.uploading' | translate }}
            <span class="font-semibold">{{ pending.name }}</span>
        </span>
    </div>
    <div class="text-xs | lg:text-base" *ngIf="pending$ | async as pending">{{ 'uploader.progress' | translate: { percent: pending.progress } }}</div>
    <div class="ml-4">
        <img src="/assets/images-optimized/upload-placeholder.webp" alt="Uploader" />
    </div>
</div>

<div @openClose *ngIf="filesInQueue$ | async as filesInQueue" class="text-2xs queue | lg:text-sm">
    {{ 'uploader.in-queue' | translate: { count: filesInQueue } }}
</div>

<ng-template #preparingRef>
    <span>{{ 'uploader.preparing' | translate }}</span>
</ng-template>
