<div
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
    class="flex items-center justify-between px-4 py-2 rounded bg-{{ message.severity }}/85 typo-body1"
>
    <div>{{ text }}</div>

    <button (click)="onMessageCloseClick()">
        <svg [attr.height]="24" [attr.width]="24">
            <circle
                [attr.stroke-width]="1.5"
                [attr.transform]="'rotate(-90, 12, 12)'"
                [attr.fill]="'transparent'"
                [attr.r]="10"
                [attr.cx]="12"
                [attr.cy]="12"
                [attr.stroke-dasharray]="62.8"
                [style.strokeDashoffset]="progress$ | async"
                class="stroke-{{ message.severity }}"
            />
        </svg>
    </button>
</div>
