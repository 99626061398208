import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { User, UsersValue } from '../models';

export const usersActions = createActionGroup({
    source: 'Users',
    events: {
        'Get Users': props<{ value: UsersValue }>(),
        'Get Users Success': props<{ totalItems: number; users: User[] }>(),
        'Get Users Error': emptyProps(),

        Clear: emptyProps(),
    },
});
