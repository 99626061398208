import { Injectable, inject } from '@angular/core';
import { UrlTree, Router } from '@angular/router';
import { AdminUserFacade } from '@app/store/admin-user';
import { Observable, map, take } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserNotLoggedInGuard {
    private readonly adminUserFacade = inject(AdminUserFacade);
    private readonly router = inject(Router);

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.adminUserFacade.loaded$.pipe(
            map((loaded) => {
                if (loaded === true) {
                    return this.router.createUrlTree(['/dashboard']);
                }
                return true;
            }),
            take(1),
        );
    }
}
