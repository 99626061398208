import { NgModule } from '@angular/core';
import { LoadImageDirective } from './load-image.directive';
import { MediaStoreModule } from '@app/store/media';
import { LoaderComponent } from '@app/shared/components';

@NgModule({
    declarations: [LoadImageDirective],
    imports: [LoaderComponent, MediaStoreModule],
    exports: [LoadImageDirective],
})
export class LoadImageModule {}
