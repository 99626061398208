import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { FileInfoDto } from '../models/file-info.dto';
import { FileInfo } from '../models/file-info';
import { ApiResponse } from '@app/shared/models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);

    getImage(id: string): Observable<FileInfo> {
        return this.httpClient.get<ApiResponse<FileInfoDto>>(`api/v1/files/info/${id}`).pipe(
            map((response) => {
                const file = new FileInfo(response.data);
                if (file.completedS3 === false) {
                    throw Error();
                }
                return file;
            }),
        );
    }
}
