import { createReducer, on } from '@ngrx/store';
import { adminUserActions } from '../actions/admin-user.actions';
import { AdminUser } from '../models';

export interface AdminUserState {
    loaded: boolean | null;
    adminUser: AdminUser | null;
}

export const initialState: AdminUserState = {
    loaded: null,
    adminUser: null,
};

export const adminUserReducer = createReducer(
    initialState,
    on(adminUserActions.getAdminUser, (state): AdminUserState => ({ ...state, loaded: null })),
    on(adminUserActions.getAdminUserSuccess, (state, payload): AdminUserState => ({ ...state, loaded: true, adminUser: payload.adminUser })),
    on(adminUserActions.getAdminUserError, (state): AdminUserState => ({ ...state, loaded: false })),

    on(adminUserActions.clear, (): AdminUserState => ({ ...initialState })),
);
