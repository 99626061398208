import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { UsersFacade } from '../facades/users.facade';

export const usersCleanup: CanDeactivateFn<unknown> = () => {
    const usersFacade = inject(UsersFacade);

    usersFacade.clear();
    return true;
};
