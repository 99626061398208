import { Injectable, inject } from '@angular/core';
import { asapScheduler } from 'rxjs';
import { Store } from '@ngrx/store';
import { mediaActions } from '../actions/media.actions';
import { selectImageUrl, selectLoaded } from '../selectors/media.selector';

@Injectable({
    providedIn: 'root',
})
export class MediaFacade {
    private readonly store = inject(Store);

    imageUrl$ = (id: string) => this.store.select(selectImageUrl(id));
    imageLoaded$ = (id: string) => this.store.select(selectLoaded(id));

    getImage(id: string): void {
        asapScheduler.schedule(() => this.store.dispatch(mediaActions.getImage({ id })));
    }
}
