import { CommonModule } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import { Blur } from './backdrop.animation';

@Component({
    selector: 'app-backdrop',
    templateUrl: './backdrop.component.html',
    styleUrls: ['./backdrop.component.scss'],
    standalone: true,
    imports: [CommonModule],
    animations: [Blur],
})
export class BackdropComponent {
    @HostBinding('@blur') get(): void {}
}
