import { UsersValue } from './users.value';

export class UsersValueDto {
    readonly params: {
        page: number;
        limit: number;
    };

    constructor(usersValue: UsersValue) {
        this.params = {
            page: usersValue.page,
            limit: usersValue.limit,
        };
    }
}
