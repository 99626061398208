import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { USERS_FEATURE_KEY } from './keys';
import { usersReducer } from './reducers/users.reducer';
import { UsersEffects } from './effects/users.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(USERS_FEATURE_KEY, usersReducer), EffectsModule.forFeature([UsersEffects])],
})
export class UsersStoreModule {}
