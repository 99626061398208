import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShellPanel, ShellAuth } from './shells';
import { userLoaded } from './store/admin-user';
import { UserLoggedInGuard, UserNotLoggedInGuard } from './shared/guards';
import { initUploaderGuard } from './modules/uploader';
import { usersCleanup } from './store/users';

const routes: Routes = [
    {
        path: '',
        canActivateChild: [userLoaded(), initUploaderGuard()],
        children: [
            {
                path: '',
                redirectTo: 'users',
                pathMatch: 'full',
            },
            {
                path: '',
                canActivate: [UserNotLoggedInGuard],
                children: [
                    ShellAuth.childRoutes([
                        {
                            path: 'auth',
                            loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
                        },
                    ]),
                ],
            },
            {
                path: '',
                canActivate: [UserLoggedInGuard],
                canDeactivate: [usersCleanup],
                children: [
                    ShellPanel.childRoutes([
                        {
                            path: '',
                            children: [
                                {
                                    path: 'dashboard',
                                    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
                                },
                                {
                                    path: 'users',
                                    loadChildren: () => import('./users/users.module').then((m) => m.UsersModule),
                                },
                                {
                                    path: 'spotify',
                                    loadChildren: () => import('./spotify/spotify.module').then((m) => m.SpotifyModule),
                                },
                            ],
                        },
                    ]),
                    {
                        path: 'logout',
                        loadChildren: () => import('./logout/logout.module').then((m) => m.LogoutModule),
                    },
                ],
            },
        ],
    },
    {
        path: '**',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule {}
