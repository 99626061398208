import { Directive, Input, OnChanges, OnDestroy, SimpleChanges, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { MediaFacade } from '@app/store/media';

@Directive({
    selector: '[appImage]',
    standalone: true,
})
export class ImageDirective implements OnChanges, OnDestroy {
    private mediaFacade = inject(MediaFacade);

    @Input({ required: true, alias: 'appImage' }) imageId: string | undefined;

    private subscriptions$ = new Subscription();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['appImage'] && typeof changes['appImage'] === 'string') {
            this.mediaFacade.getImage(changes['appImage']);
        }
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }
}
