import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { adminUserActions } from '../actions/admin-user.actions';
import { AdminUser } from '../models';
import { selectLoaded, selectAdminUser } from '../selectors/admin-user.selector';

@Injectable({
    providedIn: 'root',
})
export class AdminUserFacade {
    private readonly store = inject(Store);

    loaded$ = this.store.select(selectLoaded);
    adminUser$ = this.store.select(selectAdminUser).pipe(filter((data): data is AdminUser => data !== null));

    getAdminUser(): void {
        this.store.dispatch(adminUserActions.getAdminUser());
    }

    clear(): void {
        this.store.dispatch(adminUserActions.clear());
    }
}
