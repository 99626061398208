import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, catchError, finalize, map, mergeMap } from 'rxjs';
import { LoadersFacade } from '@app/store/loader';
import { adminUserActions } from '../actions/admin-user.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class AdminUserEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);
    private readonly loadersFacade = inject(LoadersFacade);

    getAdminUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(adminUserActions.getAdminUser),
            mergeMap(() => {
                this.loadersFacade.add('get-admin-user');

                return this.httpService.getAdminUser().pipe(
                    map((adminUser) => adminUserActions.getAdminUserSuccess({ adminUser })),
                    catchError(() => of(adminUserActions.getAdminUserError())),
                    finalize(() => this.loadersFacade.delete('get-admin-user')),
                );
            }),
        );
    });
}
