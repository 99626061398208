import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AdminUser, AdminUserDto } from '../models';
import { ApiResponse } from '@app/shared/models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);

    getAdminUser(): Observable<AdminUser> {
        return this.httpClient.get<ApiResponse<AdminUserDto>>(`api/v1/user/me`).pipe(map((res) => new AdminUser(res.data)));
    }
}
