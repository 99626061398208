import { Lang } from '@app/i18n';
import { AdminUserDto } from './admin-user.dto';

export class AdminUser {
    id: string;
    email: string;
    language: Lang;

    constructor(adminUserDto: AdminUserDto) {
        this.id = adminUserDto.id;
        this.email = adminUserDto.email;

        switch (adminUserDto.language) {
            case 'pl':
                this.language = 'pl-PL';
                break;
            case 'en':
                this.language = 'en-EN';
                break;
        }
    }
}
