import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AdminUser } from '../models';

export const adminUserActions = createActionGroup({
    source: 'Admin User',
    events: {
        'Get Admin User': emptyProps(),
        'Get Admin User Success': props<{ adminUser: AdminUser }>(),
        'Get Admin User Error': emptyProps(),

        Clear: emptyProps(),
    },
});
