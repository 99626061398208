import { Routes, Route } from '@angular/router';
import { adminUserCleanup } from '@app/store/admin-user';
import { ShellPanelComponent } from '../components/shell/shell.component';

export class ShellPanel {
    static childRoutes(routes: Routes): Route {
        return {
            path: '',
            canDeactivate: [adminUserCleanup],
            component: ShellPanelComponent,
            children: routes,
        };
    }
}
