import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MediaState } from '../reducers/media.reducer';
import { MEDIA_FEATURE_KEY } from '../keys';

const selectState = createFeatureSelector<MediaState>(MEDIA_FEATURE_KEY);
const selectImages = createSelector(selectState, (state) => state.images);

const selectLoaded = (id: string) => createSelector(selectState, (state) => state.loaded.get(id) ?? null);
const selectImageUrl = (id: string) =>
    createSelector(selectState, (state) => {
        const file = state.images.get(id);

        return file ? file.url : null;
    });

export { selectImages, selectImageUrl, selectLoaded };
